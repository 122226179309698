<!-- Old Version -->
<!-- <template> 
  <v-row justify="center">
    <v-dialog
      v-model="shower"
      v-if="resolutionScreen >= 500"
      persistent
      scrollable
      max-width="700px"
    >
      <v-card>
        <v-card-title
          >{{ $t("trashPage.detaildelete") }}
          <v-spacer></v-spacer>
          <v-icon :color="color.theme" dark size="35">mdi-folder-upload</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-list dense tile>
            <v-list-item
              v-for="(item, i) in showdatarestore"
              :key="i"
              v-show="item.restore_status.status === 'ER'"
              dense
              three-line
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.file_name }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.restore_status.message }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-avatar>
                <v-progress-circular
                  class="ma-0"
                  :rotate="-90"
                  :value="item.value"
                  size="40"
                  color="red"
                  >
                  <div>{{ item.restore_status.value }}</div>
                </v-progress-circular>
              </v-list-item-avatar>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :color="color.theme"
            :dark="color.darkTheme"
            @click="$emit('closedialog')"
            >{{ $t("dragdrop.close") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="shower"
      v-else
      persistent
      scrollable
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
    >
      <v-card>
        <v-card-text class="pa-5 text-center">
          <v-layout class="pt-2 pb-4">
            <v-flex d-flex justify-center class="ml-4">
              <span :style="headerPageDialog">{{ $t("trashPage.detaildelete") }}</span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon :color="color.theme" @click="$emit('closedialog')"
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
          <v-list v-for="(item, i) in showdatarestore" :key="i">
            <v-list-item dense two-line>
              <v-list-item-avatar>
                <v-progress-circular
                  class="ma-0"
                  :rotate="-90"
                  :value="item.restore_status.value"
                  size="30"
                  color="red"
                >
                  {{ item.restore_status.value }}
                </v-progress-circular>
              </v-list-item-avatar>
              <v-list-item-content class="text-left">
                <v-list-item-title :style="titleText">{{
                  item.file_name
                }}</v-list-item-title>
                <v-list-item-title :style="titleText">{{
                 item.restore_status.message
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <div class="text-center mt-6">
            <v-btn
              :style="btnAction"
              class="mr-2"
              height="40px"
              width="90%"
              color="red"
              outlined
              @click="$emit('closedialog')"
              >{{ $t("changeName.close") }}</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template> -->

<!-- New Version -->
<template>
  <v-content>
    <v-row justify="center">
    <v-dialog
      v-model="shower"
      v-if="resolutionScreen >= 500"
      persistent
      scrollable
      max-width="700px"
    >
      <v-card>
        <v-card-title
          >{{ $t("multirecovery.list_filefolder_recov") }}
          <v-spacer></v-spacer>
          <v-icon :color="color.theme" dark size="35">mdi-folder-upload</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="ml-n2" ref="scrollCard">
          <br />
          <v-card-actions class="py-3">
          <v-layout class="align-center" row align-center v-if="group_recovery.filter((item) => item.file_status === $t('multirecovery.waittomove')).length > 0">
              <v-icon color="#FFCC00">
                mdi-alert-circle
              </v-icon>
              <span class="ml-2">
                รอย้ายไฟล์ในกรณีที่โฟลเดอร์ต้นทางถูกลบหรือถูกเปลี่ยนสิทธิ์การเข้าถึง
              </span>
            </v-layout>
        </v-card-actions>
          <v-tabs v-model="tab">
            <v-tab v-show="!disable_tab_nonesuccess" :disabled="disable_tab_nonesuccess">{{ $t("multirecovery.tabnonerecovery_success") }}</v-tab>
            <v-tab v-show="!disable_tab_success" :disabled="disable_tab_success" @click="sortingFile">{{ $t("multirecovery.tabrecovery_success") }}</v-tab>
            <v-tab>{{ $t("multirecovery.taball") }} ({{ alldatarestore.length }})</v-tab>
            <v-tabs-items v-model="tab">
              <v-tab-item >
                <v-list v-if="nonsuccess_loading === true">
                <v-list-item class="pt-4 pb-4">
                  <v-overlay  absolute color="white" dark  >
                    <v-progress-circular
                          indeterminate
                          size="35"
                          :color="color.theme"></v-progress-circular>
                    </v-overlay>
                </v-list-item>
                </v-list>
                <v-card-text v-else ref="scrollCardWait" >
                <!-- New V -->
                <v-treeview
                  ref="tree"
                  selection-type="leaf"
                  return-object
                  multiple-active
                  class="v-treeview-movefile"
                  :items="nonsuccess_group_recovery"
                  :open.sync="openIds"
                >
                <template v-slot:prepend="{item}">
                  <v-layout row class="ml-1 my-n2">
                        <v-checkbox
                        :disabled="disable_treeview_loading === true"
                        v-model="multiitemwaitmove_v2"
                        :value="item"
                        v-if="item.role === 'P' && item.file_status === $t('multirecovery.waittomove') && check_move_dis_button " />
                        <v-icon large :color="item.file_icon[1]">
                            {{ item.file_icon[0] }}
                        </v-icon>
                    </v-layout>
                </template>
                <template v-slot:label="{item}">
                  <v-list-item class="ml-n1">
                      <v-list-item-content>
                        <v-list-item-title >
                          <span class="v-treeview-node__label">
                            {{item.file_name | subStr }}
                          </span>
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="item.hasOwnProperty('message') && item.role === 'P'">
                          <span class="v-treeview-node__label">
                            {{item.message }}
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                </template>
                  <template v-slot:append="{item}">
                    <v-chip 
                    :color="item.file_status === $t('multirecovery.recovery_cancel') ? 'error' : undefined"
                    v-if="
                      item.file_status === $t('multirecovery.waittomove') || 
                      item.file_status === $t('multirecovery.recovfail')  ||
                      item.file_status === $t('multirecovery.recovery_cancel')" small>
                      {{ item.file_status }}
                    </v-chip>
                    <v-progress-circular
                      v-else-if="item.file_status === $t('multirecovery.movingfilefolder')"
                      indeterminate
                      size="20"
                      :color="color.theme"></v-progress-circular>
                    <v-icon color="green" v-else-if="item.file_status === $t('multirecovery.recovsuccess')"> mdi-check </v-icon>
                    <!-- <v-icon color="red" v-else-if="item.file_status === $t('multirecovery.recovfail')"> mdi-close </v-icon> -->
                  </template>
                </v-treeview>

                </v-card-text>
              </v-tab-item>
              <v-tab-item >
                <v-card-text> 
                <v-list>
                  <v-list-item v-for="item in success_rocovery" :key="item.file_id">
                      <v-icon large :color="item.file_icon[1]">{{ item.file_icon[0]}}</v-icon>
                      <v-list-item-content>
                        <v-list-item-title class="ml-4" @mouseover="item.show_name = true" @mouseleave="item.show_name = false">
                        {{ item.file_name }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="ml-4" v-if="
                        item.file_status_message === 'This file has been moved' || 
                        item.file_status_message === 'This folder has been moved'">
                          {{$t("multirecovery.filefoldermoved")}}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="ml-4"
                          v-if="item.file_status === 'รอย้ายไฟล์' || item.file_status === 'Wait to move'">
                          {{$t("multirecovery.filenoparent")}}
                        </v-list-item-subtitle>
                        <!-- <v-list-item-subtitle class="ml-4">
                          <v-progress-linear
                            v-if="check_show_progress === true && item.file_value !== 100 && item.file_value !== 500"
                            :value="item.file_value"
                            color="light-blue"
                          ></v-progress-linear>
                        </v-list-item-subtitle> -->
                    </v-list-item-content>
                    <v-list-item-action >
                      <v-chip v-if="item.file_value !== 500" class="ma-2 font-weight-bold" 
                      :color="
                      item.file_status === $t('multirecovery.recovering') ? color.theme : 
                      item.file_status === $t('multirecovery.recovsuccess') ? 'green' : 
                      item.file_status === $t('multirecovery.recovfail') ? 'red' : 
                      item.file_status === $t('multirecovery.allowpermission')  ? color.theme :'#F37958' " label outlined>
                        {{ item.file_status }}
                      </v-chip>
                      <v-chip v-else class="ma-2 font-weight-bold" color="red" label outlined>
                        {{ item.file_status }}
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>
              </v-tab-item> 
              <v-tab-item>
                <!-- <v-list-item v-for="item in alldatarestore" :key="item.file_id">
                    <v-icon @mouseover="item.show_name = true" @mouseleave="item.show_name = false" large :color="item.file_icon[1]">{{ item.file_icon[0]}}</v-icon>
                    <v-badge
                      :value="item.show_name"
                      color="grey"
                      :content="item.file_name"
                      
                      transition="slide-y-transition"
                    ></v-badge>
                    <v-list-item-content>
                      <v-list-item-title class="ml-4" @mouseover="item.show_name = true" @mouseleave="item.show_name = false">
                        {{ item.file_name }}</v-list-item-title
                      >
                      <v-list-item-subtitle class="ml-4">
                        <v-progress-linear
                          v-if="check_show_progress === true && item.file_value !== 100 && item.file_value !== 500"
                          :value="item.file_value"
                          color="light-blue"
                        ></v-progress-linear>
                        <span v-else>
                          {{ item.file_status === $t("multirecovery.recovsuccess") || 
                              item.file_status === $t('multirecovery.recovfail')  ? '' : item.file_status_message }}
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action >
                      <v-chip v-if="item.file_value !== 500" class="ma-2 font-weight-bold" 
                      :color="
                      item.file_status === $t('multirecovery.recovering') ? color.theme : 
                      item.file_status === $t('multirecovery.recovsuccess') ? 'green' : 
                      item.file_status === $t('multirecovery.recovfail') ? 'red' : 
                      item.file_status === $t('multirecovery.allowpermission')  ? color.theme :'#F37958' " label outlined>
                        {{ item.file_status }}
                      </v-chip>
                      <v-chip v-else class="ma-2 font-weight-bold" color="red" label outlined>
                        {{ item.file_status }}
                      </v-chip>
                    </v-list-item-action>
                </v-list-item> -->
                <!-- NEW V -->
                <template> 
                <v-card-text ref="scrollCardAll" >
                  <v-treeview
                  ref="tree"
                  return-object
                  multiple-active
                  class="v-treeview-allfile"
                  :items="group_recovery"
                >
                  <template v-slot:prepend="{item}">
                      <v-icon large :color="item.file_icon[1]">
                        {{ item.file_icon[0] }}
                      </v-icon>
                  </template>
                  <template v-slot:label="{item}">
                    <v-list-item class="ml-n2">
                      <v-list-item-content>
                        <v-list-item-title >
                          <span class="v-treeview-node__label">
                            {{item.file_name | subStr }}
                          </span>
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="item.hasOwnProperty('message') && item.role === 'P'">
                          <span class="v-treeview-node__label">
                            {{item.message }}
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:append="{item}">
                    <v-chip v-if="item.file_status === $t('multirecovery.allowpermission') && item.role === 'P'" small>
                      {{ $t('multirecovery.allowpermission') }}
                    </v-chip>
                    <v-chip v-else-if="item.file_status === $t('multirecovery.notallowpermission') && item.role === 'P'" small>
                      {{ $t('multirecovery.notallowpermission') }}
                    </v-chip>
                    <v-progress-circular
                      v-else-if="item.file_status === $t('multirecovery.recovering')"
                      indeterminate
                      size="20"
                      :color="color.theme"></v-progress-circular>
                      <v-chip 
                      :color="item.file_status === $t('multirecovery.recovery_cancel') ? 'error' : undefined"
                      v-else-if="
                      item.file_status === $t('multirecovery.waittomove')  ||
                      item.file_status === $t('multirecovery.recovfail') || 
                      item.file_status === $t('multirecovery.recovery_cancel') && item.role === 'P'" small>
                        {{ item.file_status }}
                    </v-chip>
                    <v-icon color="green" v-else-if="item.file_status === $t('multirecovery.recovsuccess')"> mdi-check </v-icon>
                    <!-- <v-icon color="red" v-else-if="item.file_status === $t('multirecovery.recovfail')"> mdi-close </v-icon> -->
                  </template>
                  </v-treeview>
                </v-card-text>
                </template> 
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-flex row align-center class="ml-4" v-if="show_all_select && tab === 0">
            <v-checkbox v-model="checkAll" @click="checkAllItem" class="ma-1 pa-1" hide-details="true"
            :disabled="disable_treeview_loading === true" ></v-checkbox>
            <span>
              {{ $t('multirecovery.selectAll') }}
            </span>
          </v-flex>
          <v-spacer></v-spacer>
          <v-btn
            v-if="check_show_progress === false && check_show_button === false"
            color="red"
            outlined
            @click="$emit('closedialog')"
            >{{ $t("changeName.close") }}</v-btn>
          <v-btn
            :color="color.theme"
            v-if="check_show_progress === false && check_show_button === false && checkPermissionAll === false"
            :dark="color.darkTheme"
            @click="summmitrecoveryfile_v2(), (check_show_button = true), (check_show_progress = true)"
            >{{$t("multirecovery.recovbtn")}}</v-btn
          >
          <!-- Add New   -->
          <!-- <v-btn
            v-show="check_move_dis_button"
            :color="color.theme"
            :dark="color.darkTheme"
            :disabled="multiitemwaittimove.length <= 0"
            @click="movefilefolder_backup"
            >{{$t("multirecovery.movefilefolder")}}
          </v-btn> -->
          <!-- New V -->
          <v-btn
            v-if="check_move_dis_button"
            :color="color.theme"
            :dark="disable_treeview_loading === true ? null : color.darkTheme"
            :disabled="disable_treeview_loading === true || multiitemwaitmove_v2.length <= 0 "
            @click="movefilefolder_backup" 
            >{{$t("multirecovery.movefilefolder")}}</v-btn
          >
          <!-- <v-btn 
          color="red" 
          :disabled="check_dis_button" 
          v-if="check_show_button === true" 
          outlined 
          @click="closedetailrestore">
            {{$t('dialogmultidelete.buttonclose')}}
          </v-btn> -->

          <!-- CANCEL  -->
          <v-btn color="red" depressed :dark="color.darkTheme"
          v-if="check_show_progress === true && check_dis_button === true"
          @click="cancelRecovery()"> ยกเลิก</v-btn>

           <!-- V2 -->
           <v-btn  color="red  darken-1 white--text" depressed :disabled="disable_treeview_loading === true || check_dis_button" 
          v-if="check_show_button === true" 
          @click="closedetailrestore">{{$t('dialogmultidelete.buttonclose')}}</v-btn>
          
          <!-- ---- -->
        </v-card-actions>
      </v-card>
    </v-dialog>



    <v-dialog
      v-model="shower"
      v-else
      persistent
      scrollable
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
    >
      <v-card>
        <v-card-text class="pa-5">
          <v-layout class="pt-2 pb-1">
            <v-flex d-flex justify-center class="ml-8">
              <span :style="headerPage">{{
               $t("multirecovery.list_filefolder_recov")
              }}</span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon
                :color="$vuetify.theme.dark ? 'white' : color.theme"
                @click="closedetailrestore"
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
        </v-card-text>
        <!-- <v-card-title>
          {{ $t("trashPage.detaildelete") }}
          <v-spacer></v-spacer>
          <v-icon :color="color.theme" dark size="35">mdi-folder-upload</v-icon>
        </v-card-title>
        <v-divider></v-divider> -->
        <v-card-text  >
          <v-tabs v-model="tab">
           <v-tab v-show="!disable_tab_nonesuccess" :disabled="disable_tab_nonesuccess" >{{ $t("multirecovery.tabnonerecovery_success") }}</v-tab>
            <v-tab v-show="!disable_tab_success" :disabled="disable_tab_success" >{{ $t("multirecovery.tabrecovery_success") }}</v-tab>
            <v-tab>{{ $t("multirecovery.taball") }} ({{ alldatarestore.length }})</v-tab>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-list v-if="nonsuccess_loading === true">
                <v-list-item class="pt-4 pb-4">
                  <v-overlay  absolute color="white" dark  >
                    <v-progress-circular
                          indeterminate
                          size="35"
                          :color="color.theme"></v-progress-circular>
                    </v-overlay>
                </v-list-item>
              </v-list>
              <template v-else>
                <div class="pt-3 my-4">
                  <v-treeview
                      ref="tree"
                      selection-type="leaf"
                      return-object
                      multiple-active
                      :items="nonsuccess_group_recovery"
                      class="v-treeview-movefile">
                      <template v-slot:prepend="{item}"  >
                      <v-layout row class="my-n2">
                          <!-- <v-checkbox
                          :disabled="disable_treeview_loading === true"
                          v-model="multiitemwaitmove_v2"
                          :value="item"
                          v-if="item.role === 'P' && item.file_status === $t('multirecovery.waittomove')" /> -->
                          <v-icon large :color="item.file_icon[1]">
                              {{ item.file_icon[0] }}
                          </v-icon>
                      </v-layout>
                      </template>
                      <template v-slot:label="{item}">
                        <!-- <span class="v-treeview-node__label">{{item.file_name | subStr }}</span> -->
                        <v-list-item class="ml-n1">
                          <v-list-item-content>
                            <v-list-item-title >
                              <span class="v-treeview-node__label">
                                {{item.file_name | subStr }}
                              </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <!-- New V -->
                      <template v-slot:append="{item}">
                        <v-progress-circular
                          v-if="item.file_status === $t('multirecovery.movingfilefolder')"
                          indeterminate
                          size="20"
                          :color="color.theme"></v-progress-circular>
                        <v-icon color="green" v-else-if="item.file_status === $t('multirecovery.recovsuccess')"> mdi-check </v-icon>
                        <v-icon color="red" v-else-if="item.file_status === $t('multirecovery.recovfail')"> mdi-close </v-icon>
                      </template>
                  </v-treeview>
                </div>
              </template>
              </v-tab-item>
              <v-tab-item>
                <v-list>
                  <v-list-item v-for="item in success_rocovery" :key="item.file_id" class="pt-3">
                    <v-icon large :color="item.file_icon[1]">{{ item.file_icon[0]}}</v-icon>
                    <v-list-item-content>
                      <v-list-item-title class="ml-4">
                        {{ item.file_name | subStr }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="ml-4" v-if="
                        item.file_status_message === 'This file has been moved' || 
                        item.file_status_message === 'This folder has been moved'">
                        {{$t("multirecovery.filefoldermoved")}}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon 
                      color="green" >
                          mdi-check-circle
                      </v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <!-- <v-list-item v-for="item in success_rocovery" :key="item.file_id" class="pt-3">
                  <div>
                    <v-icon large color="primary">{{ item.file_type === "folder" ? "folder" : "mdi-file " }}</v-icon>
                  </div>
                  <v-list-item-content class="ml-4">
                    <v-list-item-title  @mouseover="item.show_name = true" @mouseleave="item.show_name = false">
                      {{ item.file_name | subStr }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item> -->
              </v-tab-item>
              <v-tab-item>
                <!-- <v-list>
                  <v-list-item v-for="item in alldatarestore" :key="item.file_id">
                    <v-icon large :color="item.file_icon[1]">{{ item.file_icon[0]}}</v-icon>
                      <v-list-item-content >
                        <v-list-item-title class="ml-4 mt-5">
                          <span>
                            {{ item.file_name | subStr }}
                          </span>
                        </v-list-item-title>
                        <v-list-item-subtitle class="ml-4">
                          <p v-if="item.file_value !== 500" class="font-weight-bold p-2" style="font-size: 13px"
                            :color="
                            item.file_status === $t('multirecovery.recovering') ? color.theme : 
                            item.file_status === $t('multirecovery.recovsuccess') ? 'green' : 
                            item.file_status === $t('multirecovery.recovfail') || item.file_status === 'Not complete' ? 'red' : 
                            item.file_status === $t('multirecovery.allowpermission')  ? 
                            color.theme :'#F37958' " label outlined>
                              {{ item.file_status }}
                          </p>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-progress-circular
                            model-value="16"
                            v-if="check_show_progress === true && item.file_value !== 100 && item.file_value !== 500"
                            :value="item.file_value"
                            :color="color.theme"
                            size="28">
                        </v-progress-circular>
                        <v-icon 
                        color='#F37958'
                        v-if="
                        item.file_status === 'Wait to move' ||
                        item.file_status === 'รอย้ายไฟล์'" >
                            mdi-information
                        </v-icon>
                        <v-icon 
                        color="green"
                        v-if="
                        item.file_status === 'Complete' || 
                        item.file_status === 'กู้คืนไฟล์/โฟลเดอร์สำเร็จ'" >
                            mdi-check-circle
                        </v-icon>
                      </v-list-item-action>
                  </v-list-item>
                </v-list> -->
                <template> 
                <div >
                  <v-treeview
                  ref="tree"
                  return-object
                  multiple-active
                  class="v-treeview-allfile"
                  :items="group_recovery"
                >
                  <template v-slot:prepend="{item}">
                      <v-icon large :color="item.file_icon[1]" class="ml-n2">
                        {{ item.file_icon[0] }}
                      </v-icon>
                  </template>
                  <template v-slot:label="{item}">
                    <v-list-item class="ml-n2 mt-3">
                      <v-list-item-content>
                        <v-list-item-title >
                          <span class="v-treeview-node__label">
                            {{item.file_name | subStr }}
                          </span>
                        </v-list-item-title>
                        <v-list-item-subtitle class="p-2" >
                          <p v-if="item.file_value !== 500" class="font-weight-bold" style="font-size: 13px"
                          :color="
                          item.file_status === $t('multirecovery.recovering') ? color.theme : 
                          item.file_status === $t('multirecovery.recovsuccess') ? 'green' : 
                          item.file_status === $t('multirecovery.recovfail') ? 'red' : 
                          item.file_status === $t('multirecovery.allowpermission')  ? color.theme :'#F37958'"
                          dark >
                            {{ item.file_status }}
                            <!-- {{ item.file_status === $t("multirecovery.recovsuccess") ? '' : item.file_status_message }} -->
                          </p>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:append="{item}">
                    <v-progress-circular
                      v-if="item.file_status === $t('multirecovery.recovering')"
                      indeterminate
                      size="18"
                      :color="color.theme"></v-progress-circular>
                    <v-icon color="warning" v-else-if="item.file_status === $t('multirecovery.waittomove')"> mdi-alert-circle </v-icon>
                    <v-icon color="green" v-else-if="item.file_status === $t('multirecovery.recovsuccess')"> mdi-check </v-icon>
                    <v-icon color="red" v-else-if="item.file_status === $t('multirecovery.recovfail')"> mdi-close </v-icon>
                  </template>
                  </v-treeview>
                </div>
                </template> 
              </v-tab-item>
            </v-tabs-items>
        </v-tabs>
        </v-card-text>

        <div class="text-center mb-4">
          <v-btn
            v-if="check_show_progress === false && check_show_button === false"
            small
            :style="btnAction"
            class="mr-6"
            height="40px"
            width="40%"
            color="red"
            outlined
            @click="$emit('closedialog')"
            >{{ $t("changeName.close") }}</v-btn>

          <v-btn
            v-else-if="check_show_button === true"
            small
            :style="btnAction"
            class="mr-6"
            height="40px"
            width="40%"
            color="red"
            outlined
            :disabled="disable_treeview_loading === true || check_dis_button"
            @click="closedetailrestore"
            >{{ $t("changeName.close") }}</v-btn>

          <v-btn
            small
            v-if="tab === 0 && count_waittomove >= 1 && nonsuccess_loading === false"
            class="elevation-0"
            :style="btnAction"
            height="40px"
            width="40%"
            :color="color.theme"
            :dark="multiitemwaitmove_v2.length <= 0 || disable_treeview_loading === true ? null : color.darkTheme"
            :disabled="disable_treeview_loading === true || multiitemwaitmove_v2.length <= 0"
            @click="movefilefolder_backup(), (check_show_progress = true)"
            >{{$t("multirecovery.move")}}</v-btn>

          <v-btn
            small
            v-else 
            class="elevation-0"
            :style="btnAction"
            height="40px"
            width="40%"
            :color="color.theme"
            :dark="check_show_progress === true ? null : color.darkTheme"
            :disabled="check_show_progress"
            @click="summmitrecoveryfile_v2(), (check_show_button = true), (check_show_progress = true), (check_restore =true)"
            >{{$t("multirecovery.recovbtn")}}</v-btn>
       
          </div>
      </v-card>
    </v-dialog>
  </v-row>

  <dialogmovefilefrombackup
      :show="opendlmovefile"
      :filedata="newsortmultimovefilefolder"
      :parentfolder="parentfolder"
      @updatemultidatafile="closeMultiFileandFolder"
      @closedlmovefile="(opendlmovefile = false)"
      >
    </dialogmovefilefrombackup>
  </v-content>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const dialogmovefilefrombackup = () => import('../optional/dialog-movefilefrombackup')
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  props: ["show", "showdatarestore", "filefoldersuccess", "countError"],
  components : {
    dialogmovefilefrombackup
  },
  data: function() {
    return {
      listdataupload: [],
      fileUpload: [],
      showMenu: false,
      showitem: false,
      x: 0,
      y: 0,
      
      // New variable
      cancelCheck: false,
      parentfolder: "",
      statusrestore: "",
      count_waittomove: 0,
      count_restoring: 0,
      opendlmovefile: false,
      show_all_select: true,
      checkAll: false,
      check_restore:false,
      check_dis_button:true,
      check_move_dis_button: false,
      check_show_progress: false,
      check_show_button: false,
      checkPermissionAll: false,
      nonsuccess_loading: true,
      disable_tab_success: true,
      disable_tab_nonesuccess: true, 
      disable_treeview_loading: false,
      tab: null,
      openIds: [],
      group_recovery: [],
      success_rocovery: [],
      nonesuccess_recovery: [],
      nonsuccess_group_recovery: [],
      multiitemwaittimove: [],
      multiitemwaitmove_v2: [],
      selectionWithParents: [],
      newsortmultimovefilefolder: [],
      newsortfilefolder: [],
      alldatarestore: [],
      successfilefolder: [],
      child_item: [],
      moveto: []
    };
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          this.show_all_select = false
        }
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("closedialog");
        }
      },
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // style อักษร header ของ mobile
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    // style อักษรใน dialog ของ mobile
    titleText() {
      return "color:black; font-size: 14px; line-height: 28px;";
    },
    // style ปุ่ม ของ mobile
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    // style อักษร header ของ mobile
    headerPageDialog() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 600;
      } else {
        return '400px';
      }
    },
  },

  filters: { // Add 
    subStr: function(string) {
      if (string == null || string == "" || string == undefined) {
        return string;
      } else {
        if (string.length > 25) return string.substring(0, 25) + "...";
        else return string;
      }
    },
  },
  watch: {
    show: function(val) {
      this.alldatarestore = []
      if (val === true) {
        this.checkAll = false
        this.fn_loaddata_v2 ()
        this.count_restoring = 0;
        this.count_waittomove = 0
      }
    },
    multiitemwaittimove (newVal, oldVal) {
      try {
        if(newVal.length <= 0){
          this.checkAll = false
        }
        else{
          let _selectedNodes = []
          let _treenodes = this.$refs['tree'].nodes
          for (const key in _treenodes) {
            if (Object.hasOwnProperty.call(_treenodes, key)) { //เช็คว่า id property นี้มียุใย treenode มั้ย
              const node = _treenodes[key]; //เอาตัว obj ของ treenode ตาม index key
              if (node.isSelected){ // เช็คว่า property selected  obj ตัวนี้ถูกเลือกหรือป่าว 
                _selectedNodes.push(node.item)
              }
            }
          }
          this.selectionWithParents = _selectedNodes;
          //sort by trash_dtm and folder 
          if(this.selectionWithParents.length === this.alldatarestore.filter((item) => item.file_status === 'Wait to move' || item.file_status === 'รอย้ายไฟล์').length){
            this.checkAll =  true
          }
          else{
              this.checkAll = false
          }
          }
      } catch (err) {
        console.log("Error ", err)
      }
      
    },
    // V 2 
    multiitemwaitmove_v2 (newVal, oldVal) {
      if(newVal.length <= 0 || newVal.length < this.nonsuccess_group_recovery.length){
        this.checkAll = false
      } else {
        this.checkAll = true
      }
    },
    successfilefolder: function (val) { // ไม่ได้ใช้งาน
      if(val.length > 0){
        let nonrecovery_success = this.nonesuccess_recovery.map(obj => obj.file_id)
        //let recovery_sucess = val.map(object => object.file_id)
        let recovery_sucess = val.filter((item) => item.file_status === this.$t('multirecovery.recovsuccess')).map(object => object.file_id)
        let finall_nonreovery_success = []
        nonrecovery_success = nonrecovery_success.filter(val => !recovery_sucess.includes(val));
        for(var i = 0; i < nonrecovery_success.length; i++){
            finall_nonreovery_success.push(this.nonesuccess_recovery[this.nonesuccess_recovery.findIndex((item) => item.file_id === nonrecovery_success[i])])
        }
        for(var j = 0; j < recovery_sucess.length; j++){
            this.success_rocovery.push(this.nonesuccess_recovery[this.nonesuccess_recovery.findIndex((item) => item.file_id === recovery_sucess[j])])
        }
        this.disable_tab_success = this.success_rocovery.length > 0 ? false : true
        this.checkAll = false 
        this.multiitemwaittimove = []
        if(this.alldatarestore.filter((item) => item.file_status === 'Wait to move' || item.file_status === 'รอย้ายไฟล์').length > 0){
          this.nonesuccess_recovery = finall_nonreovery_success
          this.checkgroupchildparent()
        } else {
          this.tab = 1
          this.sortingFile()
          this.disable_tab_nonesuccess = true
          this.show_all_select = false
        }
      }
      
    },
    moveto: function (val) {
      if(val !== "" && val !== undefined && val !== null){
        this.summitmovefile_v2()
      }
    },
    tab (val) {
      if(val === 0) {
        if(this.resolutionScreen < 500){
          this.multiitemwaitmove_v2.push(this.nonsuccess_group_recovery[0])
          return
        }
        setTimeout(() => {
            this.scrollToTop()
          },200)

      }
    }
  },
  methods: { //เพิ่ม Method ใหม่ทั้งหมด
    scrollToTop () {
      this.$refs['scrollCard'].scrollTo({top: 0})
      //Array.from(document.getElementsByClassName('v-treeview-movefile')[0].children)[0].scrollTo({top: 0, behavior: 'smooth'})
    },
    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    fn_loaddata () { // ไม่ได้ใช้งาน
      for(var i = 0; i < this.showdatarestore.length; i++){
          let datarestore = {};
        datarestore["file_name"] = this.showdatarestore[i]["file_name"];
        datarestore["file_id"] = this.showdatarestore[i]["file_id"];
        datarestore["file_icon"] = this.showdatarestore[i]["file_icon"];
        //datarestore["file_status"] = this.showdatarestore[i]["permission_account_v2"] === '05' || this.showdatarestore[i]["permission_account_v2"] === '06' ? this.$t("multirecovery.allowpermission") : this.$t("multirecovery.notallowpermission") ;
        datarestore["file_status"] =  this.$t("multirecovery.allowpermission")
        datarestore["file_status_message"] = ""
        datarestore["file_status_pregress"] =""
        datarestore["file_permission"] = this.showdatarestore[i]["permission_account_v2"];
        datarestore["backup_dtm"] = this.showdatarestore[i]["backup_dtm"] 
        datarestore["file_value"] = 0;
        datarestore["show_name"] = false;
        datarestore["file_type"] = this.showdatarestore[i]["file_type"];
        datarestore["file_createdtm"] = this.showdatarestore[i]["file_createdtm"] 
        datarestore["full_path"] = this.showdatarestore[i]["full_path"]
          this.alldatarestore.push(datarestore);
        }
    },
    fn_loaddata_v2 () { // ใช้งาน
      for(var i = 0; i < this.showdatarestore.length; i++){
          let datarestore = {};
        datarestore["file_name"] = this.showdatarestore[i]["file_name"];
        datarestore["file_id"] = this.showdatarestore[i]["file_id"];
        datarestore["file_icon"] = this.showdatarestore[i]["file_icon"];
        //datarestore["file_status"] = this.showdatarestore[i]["permission_account_v2"] === '05' || this.showdatarestore[i]["permission_account_v2"] === '06' ? this.$t("multirecovery.allowpermission") : this.$t("multirecovery.notallowpermission") ;
        datarestore["file_status"] =  this.$t("multirecovery.allowpermission")
        datarestore["file_status_message"] = ""
        datarestore["file_status_pregress"] =""
        datarestore["file_permission"] = this.showdatarestore[i]["permission_account_v2"];
        datarestore["backup_dtm"] = this.showdatarestore[i]["backup_dtm"] 
        datarestore["file_value"] = 0;
        datarestore["show_name"] = false;
        datarestore["file_type"] = this.showdatarestore[i]["file_type"];
        datarestore["file_createdtm"] = this.showdatarestore[i]["file_createdtm"] 
        datarestore["full_path"] = this.showdatarestore[i]["full_path"]
        datarestore["full_path_id"] = this.showdatarestore[i]["full_path_id"]
          this.alldatarestore.push(datarestore);
        }

        this.newsortfilefolder = this.alldatarestore.filter((item) => item.file_type === "folder")
        this.newsortfilefolder = this.newsortfilefolder.sort(function(a, b) {
              return a.backup_dtm.localeCompare(b.backup_dtm);
        }).reverse()
        this.newsortfilefolder = this.newsortfilefolder.sort(function(a, b) {
          return a.full_path.split('/').length - b.full_path.split('/').length 
        })
        this.alldatarestore = this.newsortfilefolder.concat(this.alldatarestore.filter((item) => item.file_type !== "folder"))
        if(this.showdatarestore.map((item) => item.permission_account_v2).filter((permis) => permis  === '05' || permis === '06').length > 0){
        this.checkPermissionAll = false
        }
        else {
          this.checkPermissionAll = true
        }

        this.changeTreeview()


    },
    checkAllItem () {
      // V 2
      if(this.checkAll){
        this.multiitemwaitmove_v2 = this.nonsuccess_group_recovery.filter((item) => item.file_status === this.$t("multirecovery.waittomove"))
      }else {
        this.multiitemwaitmove_v2 = []
      }
    },
    async fn_multirestore() { // ## ไม่ได้ใช้งาน
      for(var i = 0; i < this.alldatarestore.length; i++){
        this.alldatarestore[i]["file_status"] = this.$t("multirecovery.recovering");
          let payload = {
              data_id: this.alldatarestore[i].file_id,
              data_type: this.alldatarestore[i]["file_type"] === 'folder' ? 'folder' : 'file' ,
              user_id: this.dataUsername,
              account_id: this.dataAccountId,
          };
          await this.fn_restore(payload, this.alldatarestore[i].file_id)
          .then(async (res) => {
                if(res.status === "recover success"){
                  this.alldatarestore[i]["file_status"] = this.$t("multirecovery.recovsuccess");
                  this.alldatarestore[i]["file_value"] = 100;
                  this.alldatarestore[i]["file_status_message"] = res.msg !== "Recovery success"? res.msg : ""
                  this.alldatarestore[i]["file_status_pregress"] = 'OK'
                  this.count_restoring++;
                  this.success_rocovery.push(this.alldatarestore[i])
                } 
                else if(res.status === "wait to move"){
                  this.count_waittomove++;
                  this.alldatarestore[i]["file_status"] = this.$t("multirecovery.waittomove");
                  this.alldatarestore[i]["file_value"] = 100;
                  this.alldatarestore[i]["file_status_message"] = res.msg
                  this.alldatarestore[i]["file_status_pregress"] = 'W'
                  //this.alldatarestore[i]["full_path"] = `/${this.showrestore[i]["full_path"].split('/')[0]} / ... / ${path_temp[path_temp.length - 2]} / ${path_temp[path_temp.length - 1]}`
                  this.nonesuccess_recovery.push(this.alldatarestore[i])
                }
                else{
                  this.alldatarestore[i]["file_status"] = this.$t("multirecovery.recovfail");
                  this.alldatarestore[i]["file_value"] = 500;
                  this.alldatarestore[i]["file_status_pregress"] = 'ERR'
                }
          })
          this.scrollToBottom();
        if(this.alldatarestore.length - i === 1){
              this.check_dis_button = false;
              this.check_move_dis_button = true
              await this.timeout(600)
              if(this.count_waittomove > 0 && this.count_restoring > 0 ){
                this.tab = 0
                this.show_all_select = true
                this.disable_tab_nonesuccess = false
                this.disable_tab_success = false
                this.checkgroupchildparent()
              }else if (this.count_waittomove > 0 && this.count_restoring <= 0 ){
                this.tab = 0
                this.show_all_select = true
                this.disable_tab_nonesuccess = false
                this.checkgroupchildparent()
              } else {
                this.tab = 1
                this.disable_tab_success = false
              }
              
              //sort wait move file/folder and complete or error
              let waitMoveItem = []
              let completeProgress = []
              waitMoveItem = this.alldatarestore.filter((item) => item.file_status_pregress === 'W')
              completeProgress = this.alldatarestore.filter((item) => item.file_status_pregress === 'OK' || item.file_status_pregress === 'ERR' )
              this.alldatarestore = [...waitMoveItem, ...completeProgress]
          }
        
      }
    },
    scrollToBottom() { // ## ไม่ได้ใช้งาน
      if(this.resolutionScreen >= 500){ // Add new
        this.$refs['scrollCard'].scrollTo({top: this.$refs['scrollCard'].scrollTop + 70,behavior: 'smooth'})
      }
    },
    async fn_restore (payload, file_id) { //## ไม่ได้ใช้งาน
        let objIndex = this.alldatarestore.findIndex((obj) => obj.file_id === file_id);
        let auth = await gbfGenerate.generateToken();
        let result = await this.axios({
          method: "POST",
          url: process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/restore_data_from_backup",
          data: payload,
          headers: { Authorization: auth.code },

          onUploadProgress: (e) => {
            if ((e.loaded / e.total) * 100 >= 95) {
              this.alldatarestore[objIndex]["file_value"] = 95;
            } else {
              this.alldatarestore[objIndex]["file_value"] = parseInt((e.loaded / e.total) * 100);
            }
          },
        });
        return new Promise((resolve, reject) => {
          try {
            if (result.data.status === "OK") {
              setTimeout(() => {
                    resolve({ status: "recover success", msg: result.data.message, code: "" });
                      }, 500);
              
            } else {
              if(result.data.errorCode === "ER205"){
                  setTimeout(() => {
                        resolve({ status: "wait to move", msg: this.$t("multirecovery.filenoparent"), code: "" });
                          }, 500);
                
              }else{
                  setTimeout(() => {
                        resolve({ status: "recover denied", msg: ": ", code: "" });
                          }, 500);
                          
                  }
            }
          } catch (err) {
            setTimeout(() => {
              resolve({ status: "recover denied", msg: ": " + err, code: "" });
            }, 500);
          }
        });
    },

    async movefilefolder_backup ( ) { // ใช้งาน
      let newSortFolder = []
      let newSortFile = []
      newSortFolder = this.selectionWithParents.filter((item) => item.file_type === "folder").sort(function(a, b) {
              return a.backup_dtm.localeCompare(b.backup_dtm);
      }).reverse()
      newSortFile = this.selectionWithParents.filter((item) => item.file_type !== "folder").sort(function(a, b) {
            return a.backup_dtm.localeCompare(b.backup_dtm);
      }).reverse()
      this.newsortmultimovefilefolder = [...newSortFolder, ...newSortFile]
      this.parentfolder = this.account_active["directory_id"]
      this.opendlmovefile = true
    },
    async movefilefolder_backup_v2 ( ) { // ไม่ได้ใช้งาน
      this.$emit('opendialogmovefileandfolder', this.multiitemwaitmove_v2)
      // this.parentfolder = this.account_active["directory_id"]
      // this.opendlmovefile = true
    },
    

    async findPath (arraytempgroup, children, path, indexpath, procressedId, indexpointer) {  // ## ไม่ได้ใช้งาน
      for(var k = indexpointer; k < arraytempgroup.length; k++){
        if(procressedId.filter((val) => val === arraytempgroup[k].id).length <= 0){
          if(arraytempgroup[k].data_type !== 'folder'){
            if(arraytempgroup[k].full_path.split('/')[indexpath] === path){
                procressedId.push(arraytempgroup[k].id)
                let tempItem = this.nonesuccess_recovery.filter((item) => item.file_id === arraytempgroup[k].id)[0]
                tempItem["id"] = tempItem["file_id"]
                tempItem["name"] = tempItem["file_name"]
                children.push(tempItem)
                }else{
                  break;
                }
          } else{
            if(arraytempgroup[k].full_path.split('/')[indexpath] === path){
                let pathcheck = arraytempgroup[k].full_path.split('/').slice(-1)[0]
                let indexpath = arraytempgroup[k].full_path.split('/').findIndex((val) => val === pathcheck)
                procressedId.push(arraytempgroup[k].id)
                let tempItem = this.nonesuccess_recovery.filter((item) => item.file_id === arraytempgroup[k].id)[0]
                tempItem["id"] = tempItem["file_id"]
                tempItem["name"] = tempItem["file_name"]
                tempItem["children"] = await this.findPath (arraytempgroup, [], pathcheck, indexpath, procressedId, indexpointer + 1)
                children.push(tempItem)
            }else {
                break;
            }
          } 
        } else {
          continue
        }     
    }
    return children
    },
    
    async checkgroupchildparent ( ) { // ## ไม่ได้ใช้งาน
      this.nonsuccess_loading = true
      await this.timeout(1000)
      let folder_type_payload = this.nonesuccess_recovery
      .filter((item) => item.file_type === 'folder')
      .map((item) => item.file_id)
      let file_type_payload = this.nonesuccess_recovery
      .filter((item) => item.file_type !== 'folder')
      .map((item) => item.file_id)
      let payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        data: {folder: folder_type_payload, file: file_type_payload}
      }
      let auth = await gbfGenerate.generateToken();
      await this.axios
      .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/group_data_for_move_backup",
          //"http://192.168.75.208:8812/api/group_data_for_move_backup",
          payload,
          { headers: { Authorization: auth.code } }
      )
      .then(async (res) => {
        console.log("Res data ", res.data.data)
        let arraytempgroup = res.data.data
        let procressedId = []
        this.nonsuccess_group_recovery = []
        for(var i = 0; i < arraytempgroup.length; i++){
          if(procressedId.length === 0 || procressedId.filter((val) => val === arraytempgroup[i].id).length <= 0){
            if(arraytempgroup[i].data_type !== 'folder'){
            let tempItem = this.nonesuccess_recovery.filter((item) => item.file_id === arraytempgroup[i].id)[0]
            tempItem["id"] = tempItem["file_id"]
            tempItem["name"] = tempItem["file_name"]
            tempItem['file_type'] = tempItem["file_type"]
            tempItem["role"] = "P"
            tempItem["selected"] = false
            this.nonsuccess_group_recovery.push(tempItem)
            procressedId.push(arraytempgroup[i].id)
          }
          else{
            let pathcheck = arraytempgroup[i].full_path.split('/').slice(-1)[0]
            let indexpath = arraytempgroup[i].full_path.split('/').findIndex((val) => val === pathcheck)
            let children = []
            let tempItem = this.nonesuccess_recovery.filter((item) => item.file_id === arraytempgroup[i].id)[0]
            tempItem["id"] = tempItem["file_id"]
            tempItem["name"] = tempItem["file_name"]
            tempItem['file_type'] = tempItem["file_type"]
            tempItem["role"] = "P"
            tempItem["selected"] = false
            tempItem["children"] = await this.findPath(arraytempgroup, children, pathcheck, indexpath, procressedId, i+1)
            this.nonsuccess_group_recovery.push(tempItem)
          }
          }
        }
        this.nonsuccess_loading = false 
        console.log("Finally non success group ", this.nonsuccess_group_recovery)
      })
      .catch((err) => {
        console.log("Error Some ", err)
      })

    },

    async checkgroup () { // ## ใช้งาน
      this.nonsuccess_loading = false 
      this.nonsuccess_group_recovery = [
        ...this.nonsuccess_group_recovery.filter((item) => item.file_type === 'folder'), 
        ...this.nonsuccess_group_recovery.filter((item) => item.file_type !== 'folder')]
    },

    sortingFile ( ) { // ใช้งาน
      let newSortFolder_success = []
        let newSortFile_success = []
        newSortFolder_success = this.success_rocovery.filter((item) => item.file_type === "folder").sort(function(a, b) {
              return a.backup_dtm.localeCompare(b.backup_dtm);
        }).reverse()
        newSortFile_success = this.success_rocovery.filter((item) => item.file_type !== "folder").sort(function(a, b) {
              return a.backup_dtm.localeCompare(b.backup_dtm);
        }).reverse()

        this.success_rocovery = [...newSortFolder_success, ...newSortFile_success]
    },
  
    async closedetailrestore () {
      this.$emit('closereloaddialog')
      await this.timeout(1000)
      this.cleardata()
      
    },  
    closeMultiFileandFolder(file_move_to) { // NEW V
      console.log("Workthis")
      this.opendlmovefile = false
      this.moveto = file_move_to
      console.log("Move to ", this.moveto)
    },
    // closeMultiFileandFolder(filefoldersuccess, child_id) {
    //   console.log("Why work this ",child_id)
    //   this.opendlmovefile = false
    //   this.successfilefolder = filefoldersuccess
    //   this.child_item = child_id
    // },
    async movefilefolder () { // ไม่ได้ใช้งาน
      
      let newAllSort = []
      let newSortFolder = []
      let newSortFile = []
      newSortFolder = this.multiitemwaittimove.filter((item) => item.file_type === "folder").sort(function(a, b) {
              return a.backup_dtm.localeCompare(b.backup_dtm);
      }).reverse()
      newSortFile = this.multiitemwaittimove.filter((item) => item.file_type !== "folder").sort(function(a, b) {
            return a.backup_dtm.localeCompare(b.backup_dtm);
      }).reverse()
      this.newsortmultimovefilefolder = [...newSortFolder, ...newSortFile]
      
      this.parentfolder = this.account_active["directory_id"]
      this.opendlmovefile = true
    },

    cleardata () {
      this.movefilesuccess = []
      this.multiitemwaittimove = []
      this.multiitemwaitmove_v2 = []
      this.newsortmultimovefilefolder = []
      this.alldatarestore = []
      this.group_recovery = []
      this.success_rocovery = []
      this.nonsuccess_group_recovery = []
      this.nonesuccess_recovery = []
      this.statusrestore = ""
      this.tab = null
      this.disable_tab_success = true
      this.disable_tab_nonesuccess = true
      this.disable_treeview_loading = false
      this.check_dis_button = true
      this.check_show_progress = false;
      this.check_show_button = false
      this.show_all_select = false
      this.check_move_dis_button = false
      this.cancelCheck = false
    },

    scrollToBottom_v2(item) { // ใข้งาน
      if(this.resolutionScreen >= 500){ // Add new
        item.scrollIntoView({behavior: 'smooth' })
      }
    },

    depressed_subfilefolder_id (arr, tempfolder, tempfile) { // ใช้งาน
      for(var i = 0; i < arr.length; i++){
        if(arr[i].file_type === 'folder'){
          tempfolder.push(arr[i].file_id)
          console.log("temp folder ", tempfolder)
          this.depressed_subfilefolder_id(arr[i].children, tempfolder, tempfile)
        } else {
          tempfile.push(arr[i].file_id)
        }
      }
      return {tempfolder, tempfile}
    },

    // TRANSFORM TREEVIEW FORMAT /// (ใช้งาน) 
    async changeTreeview () {
      let temparry = this.alldatarestore
      let procressId = []
      this.group_recovery = []
      for(var i = 0; i < temparry.length; i++){
        if(procressId.filter((val) => val === temparry[i].file_id).length <= 0){
          if(temparry[i].file_type === 'folder'){
            let name_folder = temparry[i].file_name
            let file_id = temparry[i].file_id
            let children = []
            let tempItem = this.alldatarestore.filter((item) => item.file_id === temparry[i].file_id)[0]
            tempItem["id"] = tempItem["file_id"]
            tempItem["name"] = tempItem["file_name"]
            tempItem["role"] = "P"
            tempItem["procress"] = false
            tempItem["selected"] = false
            tempItem['children'] = await this.grouptreeview(temparry, file_id, children, i+1, procressId) // TEST <-
            procressId.push(temparry[i].file_id)
            this.group_recovery.push(tempItem)
          } else {
            let tempItem = this.alldatarestore.filter((item) => item.file_id === temparry[i].file_id)[0]
            tempItem["id"] = tempItem["file_id"]
            tempItem["name"] = tempItem["file_name"]
            tempItem["role"] = "P"
            tempItem["procress"] = false
            tempItem["selected"] = false
            procressId.push(temparry[i].file_id)
            this.group_recovery.push(tempItem)
          }
        } 
      }
      console.log("Group recovery ", this.group_recovery)
    },

    async grouptreeview (alldata, file_id, child, pointer, proId) {
      for(var k = pointer; k < alldata.length; k++){
        if(proId.filter((val) => val === alldata[k].file_id).length <= 0){
        if(alldata[k]["file_type"] === 'folder'){
          if(alldata[k]["full_path_id"] === null || alldata[k]["full_path_id"] === "" ){
            continue
          }
          let full_path_id_check = alldata[k]["full_path_id"].split('/') // TEST FIULL PATH ID
          let indexof_full_path_folder = full_path_id_check.findIndex((item) => item === file_id ) // <- TEST
          full_path_id_check = full_path_id_check.slice(indexof_full_path_folder,) // <- TEST
          if(full_path_id_check.length === 2){ // <- TEST
            proId.push(alldata[k].file_id)
            let name_folder = alldata[k].file_name
            let tempChild = this.alldatarestore.filter((item) => item.file_id === alldata[k].file_id)[0]
            tempChild["id"] = tempChild["file_id"]
            tempChild["name"] = tempChild["file_name"]
            tempChild["children"] = await this.grouptreeview(alldata, alldata[k].file_id, [], pointer + 1, proId )
            child.push(tempChild)
          } 
        } else {
          if(alldata[k]["full_path_id"] === null || alldata[k]["full_path_id"] === "" ){
            continue
          }
          let full_path_id_check = alldata[k]["full_path_id"].split('/')
          let indexof_full_path_folder = full_path_id_check.findIndex((item) => item === file_id ) // <- TEST
          full_path_id_check = full_path_id_check.slice(indexof_full_path_folder,) // <- TEST
          if(full_path_id_check.length === 2){
            let tempItem = this.alldatarestore.filter((item) => item.file_id === alldata[k].file_id)[0]
            tempItem["id"] = tempItem["file_id"]
            tempItem["name"] = tempItem["file_name"]
            proId.push(alldata[k].file_id)
            child.push(tempItem)
          }
        }
          
        } else{
          continue
        }

      }
      
      return child
    },


     /// ***TEST Recovery and Move Methods V2*** ////

    updatefilestatus( arr, pbid, countmiss) { // ใช้งาน
      for(var i = 0; i < arr.length; i++){
        if(pbid.includes(arr[i].file_id)){
          countmiss++
          arr[i].file_status = this.$t("multirecovery.recovfail")
          if(arr[i].file_type === 'folder'){
            if(arr[i].children.length > 0){
              this.updatefilestatus_failrestore(arr[i].children)
            }
          }
          
        }else{
          arr[i].file_status = this.$t("multirecovery.recovsuccess");
          this.success_rocovery.push(arr[i])
          if(arr[i].file_type === 'folder'){
            if(arr[i].children.length > 0){
              countmiss = countmiss = this.updatefilestatus( arr[i].children, pbid, countmiss)
              }
          }
        }
        
      }
      return countmiss
    },

    updatefilestatus_waittomove( arr) { // ใช้งาน
      for(var i = 0; i < arr.length; i++){
        arr[i].file_status = this.$t("multirecovery.waittomove");
          if(arr[i].file_type === 'folder'){
            if(arr[i].children.length > 0){
               this.updatefilestatus_waittomove( arr[i].children)
            }
          }
        
      }
      return arr
    },

    updatefilestatus_failmove( arr) { // ใช้งาน
      for(var i = 0; i < arr.length; i++){
        arr[i].file_status = this.$t('multirecovery.recovfail')
          if(arr[i].file_type === 'folder'){
            if(arr[i].children.length > 0){
               this.updatefilestatus_failmove( arr[i].children)
            }
          }
        
      }
      return arr
    },

     // RECOVEY METHOD //

   

    updatefilestatus_failrestore( arr) { // ใช้งาน
      for(var i = 0; i < arr.length; i++){
        arr[i].file_status = this.$t('multirecovery.recovfail')
          if(arr[i].file_type === 'folder'){
            if(arr[i].children.length > 0){
               this.updatefilestatus_failrestore( arr[i].children)
            }
          }
        
      }
      return arr
    },

    async summitrecoveryfile () { // ไม่ได้ใช้งาน
      
      let sortfiletype = [
        [...this.group_recovery.filter((item) => item.file_type === 'folder')], 
        [...this.group_recovery.filter((item) => item.file_type !== 'folder')]
      ]
      console.log("Group log ", sortfiletype)
      for(var o = 0 ; o < sortfiletype.length; o++){
        let group = sortfiletype[o]
        for(var i = 0; i < group.length; i++){ // [{a, {b, c}}, {d, {e, f}}]
          if(group[i].procress === true ){
            continue
          }

          // if(group[i].file_permission === '05' || group[i].file_permission === '06' ){
            
          // } else { 
          //   group[i].procress = true
          // }

          var finally_sub_fileId
            let temp_folder = []
            let temp_file = []
            group[i]["file_status"] = this.$t("multirecovery.recovering");

             // ## เก็บ sub_folder และ sub_flie ของลูกๆ
             finally_sub_fileId = await this.depressed_subfilefolder_id(group[i].file_type === 'folder' ? group[i].children : [], temp_folder, temp_file)

            let payload = {
              account_id: this.showdatarestore.find((item) => item.file_id === group[i].file_id).account_sender,
              user_id: this.dataUsername,
              data_id: group[i]["file_id"],
              data_type: group[i]["file_type"] === "folder" ? "folder" : "file",
              sub_folder: finally_sub_fileId.tempfolder,
              sub_file: finally_sub_fileId.tempfile
            };

          
            let resultMovefile = await this.fn_restore_filefolder(payload, group[i]) 

            if(resultMovefile.status === 'OK'){
              this.count_restoring ++
              group[i].procress = true
              this.success_rocovery.push(group[i])
              if(group[i].file_type === 'folder'){
                let countmistake = 0
                // await this.updatefilestatus(group[i].children, resultMovefile.data_miss, countmistake)
                if(await this.updatefilestatus(group[i].children, resultMovefile.data_miss, countmistake) > 0){ // ## เช็ค datamiss ที่ส่งมาบาง fileid ของลูกๆนั้น
                    let findIndexItem = this.group_recovery.findIndex((item) => item.file_id === group[i].file_id)
                    console.log("Find Index ", this.group_recovery[findIndexItem])
                    this.group_recovery[findIndexItem]["message"] = 'บางไฟล์/โฟลเดอร์กู้คืนไม่สำเร็จ'
                  }
              }
            } else if (resultMovefile.status === 'WAIT TO MOVE') {
              try{
                this.count_waittomove++

                //test V
                group[i]["file_status"] = this.$t("multirecovery.waittomove");
                group[i]["procress"] = true
                //this.nonesuccess_recovery.push(this.group_recovery[i])

                if(group[i].file_type !== 'folder'){
                  // this.nonesuccess_recovery.push(this.group_recovery[i])
                  this.nonsuccess_group_recovery.push(group[i])
                } else{
                  await this.updatefilestatus_waittomove(group[i].children)
                  this.nonsuccess_group_recovery.push(group[i])
                }

                let arrayTest = group[i]["full_path"].split('/')
                arrayTest.pop()
                arrayTest = JSON.stringify(arrayTest) // [a, b]
                let arrayTemp = group
                .filter((item) => item.procress === false && arrayTest === JSON.stringify(item.full_path.split('/')
                .slice(0, -1)))
                .map((mapId) => mapId.id) 
                
                
                group.forEach((obj) => {if(arrayTemp.includes(obj.file_id)) {
                  obj.procress = true 
                  if(obj.file_permission === '05' || obj.file_permission === '06' ){
                    obj.file_status =  this.$t("multirecovery.waittomove");
                    this.nonsuccess_group_recovery.push(obj)
                  }
                }})

              } catch (err) {
                console.log("ERRORR ", err)
              }
              
            } else if (resultMovefile.status === 'FILE/FOLDER ERROR') {
                group[i]["file_status"] = this.$t('multirecovery.recovfail')
                group[i]["procress"] = true
                if(group[i]["file_type"] === 'folder'){
                  await this.updatefilestatus_failrestore(group[i].children)
                }
            } else { // NETWORK OR OTHER ERROR
              group[i]["file_status"] = this.$t('multirecovery.recovfail')
              this.show_all_select = true
              this.nonsuccess_loading = false 
              this.check_dis_button = false;
              break
            }

          let findIndexElement = this.group_recovery.findIndex((item) => item.file_id === group[i].file_id)
          this.scrollToBottom_v2(Array.from(document.getElementsByClassName('v-treeview-allfile')[0].children)[findIndexElement]) // v-treeview-node--leaf
          if(this.group_recovery.filter((item) => item.procress === true).length === this.group_recovery.length){ // then this.group_recovery.length - i === 1
              this.check_dis_button = false;
              this.check_move_dis_button = true
              await this.timeout(1000)
              if(this.count_waittomove > 0 && this.count_restoring > 0 ){
                      this.tab = 0
                      this.show_all_select = true
                      this.disable_tab_nonesuccess = false
                      this.disable_tab_success = false
                      //this.checkgroupchildparent()
                      this.checkgroup()
              }else if (this.count_waittomove > 0 && this.count_restoring <= 0){
                      this.tab = 0
                      this.show_all_select = true
                      this.disable_tab_nonesuccess = false
                      this.checkgroup()
                      //this.checkgroupchildparent()
              }else if (this.count_restoring > 0 || this.count_restoreerror > 0 && this.count_waittomove <= 0  ) {
                      this.tab = 1
                      this.show_all_select = false
                      this.disable_tab_success = false
              } 
          }


        }
      }
    },

    async summmitrecoveryfile_v2 () { // ##ใช้งาน V2
      try{
      let chuck_group_file_id = this.group_recovery.length <= 1 ? 
      [this.group_recovery] : await this.file_id_chunk(this.group_recovery, 5)
      for(var i = 0; i < chuck_group_file_id.length; i++){ // [[123], [23]]
        let check_error_round = false
        let file_id_mistake
        if(this.cancelCheck){
          this.group_recovery.filter((item) => item.file_status === this.$t("multirecovery.allowpermission")).forEach(async (item) => {
            item.file_status =  this.$t('multirecovery.recovery_cancel') 
              if(item.file_type === 'folder'){
                await this.updatefilestatus_failmove(item.children)
              }
          })
          console.log("Check Grup ", chuck_group_file_id[i])
          this.nonsuccess_loading = false 
          this.check_dis_button = false;
          this.check_move_dis_button = true
          break
        }

        const promises = await chuck_group_file_id[i].map(async item => {
          item.file_status = this.$t("multirecovery.recovering");

          // ## เก็บ file_id ของโฟลเดอรที่มีลูก
          var finally_sub_fileId
              let temp_folder = []
              let temp_file = []
              finally_sub_fileId = await this.depressed_subfilefolder_id(
              item.file_type === 'folder' ? item.children : [], temp_folder, temp_file)
          
         let payload = {
              account_id: this.showdatarestore.find((itemFind) => itemFind.file_id === item.file_id).account_sender,
              user_id: this.dataUsername,
              data_id: item.file_id,
              data_type: item.file_type === "folder" ? "folder" : "file",
              sub_folder: finally_sub_fileId.tempfolder,
              sub_file: finally_sub_fileId.tempfile
            }

          const responseRecovery =  await this.fn_restore_filefolder_v2(item, payload)
          return responseRecovery
        })
        await Promise.all(promises)
        .then(async(AllRes) => {
          for(const itemFile of AllRes){
            if(itemFile.status === 'OK'){
              this.count_restoring ++
              this.success_rocovery.push(this.group_recovery.find((item) => item.file_id === itemFile.file_id ))
              if(itemFile.file_type === 'folder'){ // #อัพเดต ไฟล์หรือโฟลเดอร์ตัวลูกๆ
                let countmistake = 0
                //await this.updatefilestatus(itemFile.children, itemFile.data_miss, countmistake)

                //เพิ่ม snipped
                if(await this.updatefilestatus(itemFile.children, itemFile.data_miss, countmistake) > 0){ // ## เช็ค datamiss ที่ส่งมาบาง fileid ของลูกๆนั้น
                    let findIndexItem = this.group_recovery.findIndex((item) => item.file_id === itemFile.file_id)
                    this.group_recovery[findIndexItem]["message"] = 'บางไฟล์/โฟลเดอร์กู้คืนไม่สำเร็จ'
                  }
              }
            } 
            else if (itemFile.status === 'WAIT TO MOVE') {
              try{
                this.count_waittomove++
                let findIndexError = this.group_recovery.findIndex((item) => item.file_id === itemFile.file_id)
                this.group_recovery[findIndexError]["file_status"] = this.$t("multirecovery.waittomove");

                // ## เก็บ file หรือ folder แบบ treeview และอัพเดตตัวลูก (แบบใหม่)
                if(this.group_recovery[findIndexError] !== 'folder'){
                  // this.nonesuccess_recovery.push(this.group_recovery[i]) // Old
                  this.nonsuccess_group_recovery.push(this.group_recovery[findIndexError])
                } else{
                  await this.updatefilestatus_waittomove(this.group_recovery[findIndexError].children)
                  this.nonsuccess_group_recovery.push(this.group_recovery[findIndexError])
                }
              } catch (err) {
                console.log("ERRORR ", err)
              }

            } 
            else if (itemFile.status === 'FILE/FOLDER ERROR') {
                let findIndexError = this.group_recovery.findIndex((item) => item.file_id === itemFile.file_id)
                this.group_recovery[findIndexError]["file_status"] = this.$t("multirecovery.recovfail");
                if(this.group_recovery[i].file_type === 'folder'){
                  await this.updatefilestatus_failmove(itemFile.children)
                }
              } 
            else {
              check_error_round = true
              file_id_mistake = itemFile
            }
          }
        })


        let group_element_node_ = await this.slice_node_treeview_chunck(Array.from(document.getElementsByClassName('v-treeview-allfile')[0].children), 5)
        this.scrollToBottom_v2(group_element_node_[i][group_element_node_[i].length - 1])
        
      

        // ## เช็ครอบของ group ว่ามี error ? อัพเดต->หยุด  : null
        if(check_error_round){
          let findIndexError = this.group_recovery.findIndex((item) => item.file_id === file_id_mistake.file_id)
          this.group_recovery[findIndexError]["file_status"] = this.$t("multirecovery.recovfail");
          if(file_id_mistake.file_type === 'folder'){
            await this.updatefilestatus_failmove(file_id_mistake.children)
          }
          this.show_all_select = true
          this.nonsuccess_loading = false 
          this.check_dis_button = false;
          break
        }

        if(chuck_group_file_id.length - 1 === i){
          this.check_dis_button = false;
              this.check_move_dis_button = true
              await this.timeout(1000)
              // ## มีไอเทมสำเร็จและรอย้าย (เปิดกู้ทั้งหมด - เปิดรอย้าย)
              if(this.count_waittomove > 0 && this.count_restoring > 0 ){
                      this.tab = 0
                      this.show_all_select = true
                      this.disable_tab_nonesuccess = false
                      this.disable_tab_success = false
                      this.checkgroup()
              }
              // ## ไม่มีไอเทมที่สำเร็จ (ปิดกู้ทั้งหมด - เปิดรอย้าย)
              else if (this.count_waittomove > 0 && this.count_restoring <= 0){
                      this.tab = 0
                      this.show_all_select = true
                      this.disable_tab_nonesuccess = false
                      this.checkgroup()
              }
              // ## ไม่มีไอเทมรอย้าย (เปิดกู้ทั้งหมด - ปิดรอย้าย)
              else if (this.count_restoring > 0 || this.count_restoreerror > 0 && this.count_waittomove <= 0  ) {
                      this.tab = 1
                      this.show_all_select = false
                      this.disable_tab_success = false
              } 
        }

      }  
      
      if(this.cancelCheck){
        await this.timeout(600)
        // ## มีไอเทมสำเร็จและรอย้าย (เปิดกู้ทั้งหมด - เปิดรอย้าย)
        if(this.count_waittomove > 0 && this.count_restoring > 0 ){
            this.tab = 0
            this.show_all_select = true
            this.disable_tab_nonesuccess = false
            this.disable_tab_success = false
            this.checkgroup()
        }
        // ## ไม่มีไอเทมที่สำเร็จ (ปิดกู้ทั้งหมด - เปิดรอย้าย)
        else if (this.count_waittomove > 0 && this.count_restoring <= 0){
            this.tab = 0
            this.show_all_select = true
            this.disable_tab_nonesuccess = false
            this.checkgroup()
        }
        // ## ไม่มีไอเทมรอย้าย (เปิดกู้ทั้งหมด - ปิดรอย้าย)
        else if (this.count_restoring > 0 || this.count_restoreerror > 0 && this.count_waittomove <= 0  ) {
            this.tab = 1
            this.show_all_select = false
            this.disable_tab_success = false
      } 
      }
    }
    catch(err){
      console.log("EER", err)
    }

    },

    async fn_restore_filefolder(payload) { // ไม่ได้ใช้งาน
      let auth = await gbfGenerate.generateToken();
        return await this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/restore_data_from_backup",
            payload,
            { headers: { Authorization: auth.code } }
          )
          .then((response) => {
            if (response.data.status === "OK") {
              let id_item = payload.data_id;
              let indexUpdateMessage = this.group_recovery.findIndex((item) => item.file_id === id_item);
              this.group_recovery[indexUpdateMessage].file_status = this.$t("multirecovery.recovsuccess");
              this.group_recovery[indexUpdateMessage].file_status_message = response.data.message;
                return new Promise((resolve, reject) => {
                  setTimeout(() => {
                    resolve({status: 'OK', message: 'Success', data_miss: ['6b18c5af1c4c1779daa43277a7c680f6','ea2ab065ae642c7112fa4d523b02e87b'] })
                  },500)
                }) 
            } 
            else {
              if(response.data.errorCode === "ER205" || response.data.errorCode === "ER209" ){
                return new Promise((resolve, reject) => {
                  setTimeout(() => {
                    resolve({status: 'WAIT TO MOVE', msg: this.$t("multirecovery.filenoparent")})
                  },500)
                }) 
              } else {
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                      resolve({status: 'FILE/FOLDER ERROR', message: 'Not success'})
                    },500)
                }) 
              }
            }
          })
          .catch((error) => {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve({status: 'AXIOS ERROR', message: this.$t('multirecovery.recovfail')})
              },500)
            }) 
          });
    },


    async fn_restore_filefolder_v2(itemFileFolder, payload) { // ##ใช้งาน
      let auth = await gbfGenerate.generateToken();
        return await this.axios
          .post(
             process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/restore_data_from_backup",
            //,
            payload,
            { headers: { Authorization: auth.code } }
          )
          .then((response) => {
            // ## Response OK
            let indexUpdateMessage = this.group_recovery.findIndex((item) => item.file_id === itemFileFolder.file_id ); // ## หา Index ไอเทมที่สำเร็จ
            if (response.data.status === "OK") {
              this.group_recovery[indexUpdateMessage].file_status = this.$t("multirecovery.recovsuccess");
              this.group_recovery[indexUpdateMessage].file_status_message = response.data.message;
                return new Promise((resolve, reject) => {
                  setTimeout(() => {
                    resolve({
                      status: 'OK', 
                      message: 'Success', 
                      file_id: this.group_recovery[indexUpdateMessage].file_id,
                      file_type: this.group_recovery[indexUpdateMessage].file_type,
                      children: this.group_recovery[indexUpdateMessage].file_type === 'folder' ? 
                      this.group_recovery[indexUpdateMessage].children : [],
                      data_miss: response.data.data_miss
                    })
                  },500)
                }) 
            } 
             // ## Response ER205(โฟลเดอร์ปลายทางไม่มี) ER209(โฟลเดอร์ปลายทางไม่มีสิทธิ์)
            else {
              if(response.data.errorCode === "ER205" || response.data.errorCode === "ER209" ){
                return new Promise((resolve, reject) => {
                  setTimeout(() => {
                    resolve({
                      status: 'WAIT TO MOVE', 
                      message: this.$t("multirecovery.filenoparent"),
                      file_id: this.group_recovery[indexUpdateMessage].file_id,
                      file_type: this.group_recovery[indexUpdateMessage].file_type,
                      children: this.group_recovery[indexUpdateMessage].file_type === 'folder' ? 
                      this.group_recovery[indexUpdateMessage].children : []
                    })
                  },500)
                }) 
              } 
              // ## Response ER อื่นๆ 
              else {
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                      resolve({
                      status: 'FILE/FOLDER ERROR', 
                      message: 'Not success',
                      file_id: this.group_recovery[indexUpdateMessage].file_id,
                      file_type: this.group_recovery[indexUpdateMessage].file_type,
                      children: this.group_recovery[indexUpdateMessage].file_type === 'folder' ? 
                      this.group_recovery[indexUpdateMessage].children : []})
                    },500)
                }) 
              }
            }
          })
          // ## Response ER Axios
          .catch((error) => {
            let indexUpdateMessage = this.group_recovery.findIndex((item) => item.file_id === itemFileFolder.file_id ); 
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve({
                  status: 'AXIOS ERROR', 
                  message: this.$t("movefiles.cannotmovefile"),
                  file_id: this.group_recovery[indexUpdateMessage].file_id,
                  file_type: this.group_recovery[indexUpdateMessage].file_type,
                  children: this.group_recovery[indexUpdateMessage].file_type === 'folder' ? 
                  this.group_recovery[indexUpdateMessage].children : []
                })
              },500)
            }) 
          });
    },

    // MOVE FILE FOLDER METHOD //

    

    async summitmovefile() { //ไม่ได้ใช้งาน
      var payload;
      let arrayIdBaseOn = this.nonsuccess_group_recovery.map((item) => item.file_id) // [A, B, C]
      this.multiitemwaitmove_v2 = this.multiitemwaitmove_v2.sort(function (a, b) {
        return arrayIdBaseOn.indexOf(a.file_id) - arrayIdBaseOn.indexOf(b.file_id);
      })
      for(var i = 0; i < this.multiitemwaitmove_v2.length; i++){ // [{a, {b, c}}, {d, {e, f}}]
          this.openIds = []
          this.openIds.push(this.multiitemwaitmove_v2[i])
          this.disable_treeview_loading = true
          this.multiitemwaitmove_v2[i].file_status = this.$t("multirecovery.movingfilefolder");
          payload = {
            data_id: this.multiitemwaitmove_v2[i].file_id,
            data_type: this.multiitemwaitmove_v2[i].file_type === "folder" ? "folder" : "file",
            account_id: this.dataAccountId,
            folder_id_move_to: this.moveto,
          };
          let resultQueryfile = this.multiitemwaitmove_v2[i].file_type === "folder" ? 
          await this.querymovefolder(payload, this.multiitemwaitmove_v2[i]) : await this.querymovefile(payload, this.multiitemwaitmove_v2[i])
          
          if(resultQueryfile.status === 'OK'){
            this.success_rocovery.push(this.multiitemwaitmove_v2[i])
            let data_miss_file_id = resultQueryfile.data_miss
            if(this.multiitemwaitmove_v2[i].file_type === 'folder'){
              let countmistake = 0
              if(await this.updatefilestatus(this.multiitemwaitmove_v2[i].children, data_miss_file_id, countmistake) > 0){
                this.multiitemwaitmove_v2[i]["message"] = 'บางไฟล์/โฟลเดอร์กู้คืนไม่สำเร็จ'
              }
              // this.updatefilestatus(this.multiitemwaitmove_v2[i].children, data_miss_file_id)
            }
          }



          if(this.multiitemwaitmove_v2.length - 1 === i){
            this.openIds = []
            await this.timeout(600)
            this.disable_tab_success = this.success_rocovery.length > 0 ? false : true
            this.checkAll = false 
            this.multiitemwaitmove_v2 = []
            this.disable_treeview_loading = false
            this.testMoveto = []
            if(this.nonsuccess_group_recovery.filter((item) => item.file_status === 'Wait to move' || item.file_status === 'รอย้ายไฟล์').length <= 0){
              this.tab = 1
              this.disable_tab_nonesuccess = true
              this.show_all_select = false
            }
          }

      }
    },

    async querymovefile(payload, item) { //ไม่ได้ใช้งาน
      let auth = await gbfGenerate.generateToken();
        return await this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/move_data_from_backup",
            payload,
            { headers: { Authorization: auth.code } }
          )
          .then((response) => {
            if (response.data.status === "OK") {
              let id_item = payload.data_id;
              let indexUpdateMessage = this.multiitemwaitmove_v2.findIndex(
                (item) => item.file_id === id_item
              );
              this.multiitemwaitmove_v2[indexUpdateMessage].file_status = this.$t(
                "multirecovery.recovsuccess"
              );
              this.multiitemwaitmove_v2[indexUpdateMessage].file_status_message =
                response.data.message;
                return new Promise((resolve, reject) => {
                  setTimeout(() => {
                    resolve({status: 'OK', message: 'Success'})
                  },500)
                }) 
            } 
            else {
              return new Promise((resolve, reject) => {
                  setTimeout(() => {
                    resolve({status: 'Denied', message: 'Not success'})
                  },500)
              }) 
            }
          })
          .catch((error) => {
            console.log('Errorr ', error)
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve({status: 'ER', message: this.$t("movefiles.cannotmovefile")})
              },500)
            }) 
          });
    },

    async querymovefolder(payload, item) {  // ไม่ได้ใช้งาน
        let auth = await gbfGenerate.generateToken();
        return await this.axios
          .post(
            //process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/move_data_from_trash",
            "http://192.168.72.137:8812/api/move_data_from_backup",
            payload,
            { headers: { Authorization: auth.code } }
          )
          .then((response) => {
            if (response.data.status === "OK") {
              let id_item = payload.data_id;
              let indexUpdateMessage = this.multiitemwaitmove_v2.findIndex(
                (item) => item.file_id === id_item
              );
              this.multiitemwaitmove_v2[indexUpdateMessage].file_status = this.$t(
                "multirecovery.recovsuccess"
              );
              this.multiitemwaitmove_v2[indexUpdateMessage].file_status_message = response.data.message;
              return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({status: 'OK', message: 'Success', data_miss: response.data.data_miss})
                },500)
              }) 

            } else {
              return new Promise((resolve, reject) => {
                  setTimeout(() => {
                    resolve({status: 'ER', message: 'Not success'})
                  },500)
              }) 
            }
          })
          .catch((error) => {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve({status: 'ER', message: this.$t("movefiles.cannotmovefile")})
              },500)
            }) 
          });
    },

    // MOVE FILE FOLDER V2 (Use Promise All, with many request) METHOD // (ใข้งาน)

    async file_id_chunk (file_id, per_chunck) { // Group Array
    const result = file_id.reduce((resultArray, item, index) => { 
      const chunkIndex = Math.floor(index/per_chunck)
      if(!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }
      resultArray[chunkIndex].push(item)
      return resultArray
      }, [])

     return result
    },

    async slice_node_treeview_chunck(arr, chunkSize) {
      const res = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
          const chunk = arr.slice(i, i + chunkSize);
          res.push(chunk);
      }
      return res;
    },

    async summitmovefile_v2() {
      try{
      this.cancelCheck = false
      this.check_show_progress = true 
      this.check_dis_button = true

      let arrayIdBaseOn = this.nonsuccess_group_recovery.map((item) => item.file_id) // [A, B, C]
      this.multiitemwaitmove_v2 = this.multiitemwaitmove_v2.length <= 1 ? 
      this.multiitemwaitmove_v2 : this.multiitemwaitmove_v2.sort(function (a, b) {
        return arrayIdBaseOn.indexOf(a.file_id) - arrayIdBaseOn.indexOf(b.file_id);
      })
      // //console.log("Chuck all showrestore as 5 array ", await this.file_id_chunk(this.nonsuccess_group_recovery, 5))
      let chuck_group_file_id = this.multiitemwaitmove_v2.length <= 1 ? 
      [this.multiitemwaitmove_v2] : await this.file_id_chunk(this.multiitemwaitmove_v2, 5)
      for(var i = 0; i < chuck_group_file_id.length; i++){ // [[123], [23]]
        let check_error_round = false
        this.disable_treeview_loading = true

        if(this.cancelCheck){
          this.group_recovery.filter((item) => item.file_status === this.$t("multirecovery.waittomove")).forEach(async (item) => {
            item.file_status =  this.$t('multirecovery.recovery_cancel') 
              if(item.file_type === 'folder'){
                await this.updatefilestatus_failmove(item.children)
              }
          })
          console.log("Chucn Group ", chuck_group_file_id[i])
            this.check_dis_button = false;
            this.show_all_select = false
            this.nonsuccess_loading = false 
            this.check_move_dis_button = false
            this.disable_treeview_loading = false
            this.moveto = ''
            this.checkAll = false 
            break
        }

        const promises = await chuck_group_file_id[i].map(async item => {
          item.file_status = this.$t("multirecovery.movingfilefolder");

          // ## เก็บ file_id ของโฟลเดอรที่มีลูก
          var finally_sub_fileId
              let temp_folder = []
              let temp_file = []
              finally_sub_fileId = await this.depressed_subfilefolder_id(
              item.file_type === 'folder' ? item.children : [], temp_folder, temp_file)

          let payload = {
            data_id: item.file_id,
            data_type: item.file_type === "folder" ? "folder" : "file",
            account_id: this.dataAccountId,
            folder_id_move_to: this.moveto,
            user_id: this.dataUsername,
            sub_folder: finally_sub_fileId.tempfolder,
            sub_file: finally_sub_fileId.tempfile
          };
           const numFruit =  await this.fn_move_filefolder_v2 (item, payload)
          return numFruit
        })
        await Promise.all(promises)
        .then(async(AllRes) => {
          for(const itemFile of AllRes){
            console.log("ItemFile ", itemFile)
            if(itemFile.status === 'OK'){
              this.success_rocovery.push(this.multiitemwaitmove_v2.find((item) => item.file_id === itemFile.file_id ))
              if(itemFile.file_type === 'folder'){
                let countmistake = 0
                if(await this.updatefilestatus(itemFile.children, itemFile.data_miss, countmistake) > 0){
                  let findIndexItem = this.multiitemwaitmove_v2.findIndex((item) => item.file_id === itemFile.file_id)
                  this.multiitemwaitmove_v2[findIndexItem]["message"] = 'บางไฟล์/โฟลเดอร์กู้คืนไม่สำเร็จ'
                }
              }
            } else if (itemFile.status === 'FILE/FOLDER ERROR') {
              let findIndexError = this.multiitemwaitmove_v2.findIndex((item) => item.file_id === itemFile.file_id)
              this.multiitemwaitmove_v2[findIndexError]["file_status"] = this.$t("multirecovery.recovfail");
              if(this.multiitemwaitmove_v2[i].file_type === 'folder'){
                await this.updatefilestatus_failmove(itemFile.children)
              }
            } else {
              check_error_round = true
            }
          }
        })


        let group_element_node_ = await this.slice_node_treeview_chunck(Array.from(document.getElementsByClassName('v-treeview-movefile')[0].children), 5)
        this.scrollToBottom_v2(group_element_node_[i][group_element_node_[i].length - 1])


        if(check_error_round){
            for(var k = 0; k < chuck_group_file_id[i].length; k++){
              let findIndexItem = this.multiitemwaitmove_v2.findIndex((item) => item.file_id === chuck_group_file_id[i][k].file_id)
              this.multiitemwaitmove_v2[findIndexItem]["file_status"] = this.$t("multirecovery.recovfail");
            }
            this.show_all_select = true
            this.nonsuccess_loading = false 
            this.check_dis_button = false;
            break
        }
        if(chuck_group_file_id.length - 1 === i){
            this.check_dis_button = false
            await this.timeout(600)
            this.disable_tab_success = this.success_rocovery.length > 0 ? false : true
            this.disable_treeview_loading = false
            this.moveto = ''
            this.multiitemwaitmove_v2 = []
            this.checkAll = false 
            if(this.nonsuccess_group_recovery.filter((item) => item.file_status === 'Wait to move' || item.file_status === 'รอย้ายไฟล์').length <= 0){
              this.tab = 1
              this.disable_tab_nonesuccess = true
              this.show_all_select = false
            }
        }

      }
      } catch(err) {
        console.log("ERROR Cat Meow ", err )
      }
    },

    async fn_move_filefolder_v2 (fileItem, payload) { // ใช้งาน
      let auth = await gbfGenerate.generateToken();
        return await this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/move_data_from_backup",
            //"http://192.168.72.137:8812/api/move_data_from_backup",
            payload,
            { headers: { Authorization: auth.code } }
          )
          .then((response) => {
            if (response.data.status === "OK") {
              let indexUpdateMessage = this.multiitemwaitmove_v2.findIndex((item) => item.file_id === fileItem.file_id);
              this.multiitemwaitmove_v2[indexUpdateMessage].file_status = this.$t("multirecovery.recovsuccess");
              this.multiitemwaitmove_v2[indexUpdateMessage].file_status_message = response.data.message;
              return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({
                      status: 'OK', 
                      message: 'Success', 
                      file_id: this.multiitemwaitmove_v2[indexUpdateMessage].file_id,
                      file_type: this.multiitemwaitmove_v2[indexUpdateMessage].file_type,
                      children: this.multiitemwaitmove_v2[indexUpdateMessage].file_type === 'folder' ? 
                      this.multiitemwaitmove_v2[indexUpdateMessage].children : [],
                      data_miss: ['ef83b643366f414ea5fb5c3e92314d4b']
                    }
                    )
                },500)
              }) 

            } else {
              let indexUpdateMessage = this.multiitemwaitmove_v2.findIndex((item) => item.file_id === fileItem.file_id);
              return new Promise((resolve, reject) => {
                  setTimeout(() => {
                    resolve({
                      status: 'FILE/FOLDER ERROR', 
                      message: 'Not success', 
                      file_id: this.multiitemwaitmove_v2[indexUpdateMessage].file_id,
                      file_type: this.multiitemwaitmove_v2[indexUpdateMessage].file_type,
                      children: this.multiitemwaitmove_v2[indexUpdateMessage].file_type === 'folder' ? 
                      this.multiitemwaitmove_v2[indexUpdateMessage].children : [],})
                  },500)
              }) 
            }
          })
          .catch((error) => {
            let indexUpdateMessage = this.multiitemwaitmove_v2.findIndex((item) => item.file_id === fileItem.file_id);
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve({
                  status: 'AXIOS ERROR', 
                  message: this.$t("movefiles.cannotmovefile"),
                  file_id: this.multiitemwaitmove_v2[indexUpdateMessage].file_id,
                  file_type: this.multiitemwaitmove_v2[indexUpdateMessage].file_type,
                })
              },500)
            }) 
          });
    },

    // ## CANCEL AXIOS
    async cancelRecovery (){
      //this.cancelSource.cancel('Cancel All Group')
      // console.log("Check Cancel Worling Here")
      this.cancelCheck = true
     }


  },
  created() {},
};
</script>
<style>
#app_currentrightclick {
  font-family: "Sarabun", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: white;
  z-index: 9999;
}
</style>
